<template>
  <div class="row mb-3 p-0">
    <label v-if="element.label" for="colFormLabel" class="col-sm-2 col-form-label col-form-label-md">{{
      element.label[lang] }}{{ isRequired(element.required) }}</label>
    <div v-if="element.type == 'p'" class="col-12">
      <FormElementBodyText :element="element" :lang="lang" />
    </div>
    <div v-if="'text|date|time|datetime-local'.indexOf(element.type) >= 0" class="col-sm-10">
      <FormElementTextInput @elementChanged="(name, data) => $emit('elementChanged', name, data)" :formData="formData"
        :value="formData[element.name]" :element="element" :lang="lang" :targetId="targetId"
        :wasValidated="wasValidated" />
    </div>
    <div v-if="'txtarea'.indexOf(element.type) >= 0" class="col-sm-10">
      <FormElementTextareaInput @elementChanged="(name, data) => $emit('elementChanged', name, data)"
        :formData="formData" :value="formData[element.name]" :element="element" :lang="lang" :targetId="targetId"
        :wasValidated="wasValidated" />
    </div>
    <div v-if="'number'.indexOf(element.type) >= 0" class="col-sm-10">
      <FormElementNumberInput @elementChanged="(name, data) => $emit('elementChanged', name, data)" :formData="formData"
        :value="formData[element.name]" :element="element" :lang="lang" :targetId="targetId"
        :wasValidated="wasValidated" />
    </div>
    <div v-if="'amount'.indexOf(element.type) >= 0" class="col-sm-10">
      <FormElementAmountInput @elementChanged="(name, data) => $emit('elementChanged', name, data)" :formData="formData"
        :value="formData[element.name]" :element="element" :lang="lang" :targetId="targetId"
        :wasValidated="wasValidated" />
    </div>
    <div v-if="element.type == 'email'" class="col-sm-10">
      <FormElementEmailInput @elementChanged="(name, data) => $emit('elementChanged', name, data)" :formData="formData"
        :valueObj="formData[element.name]" :element="element" :lang="lang" :targetId="targetId"
        :wasValidated="wasValidated" />
    </div>
    <div v-if="'select'.indexOf(element.type) >= 0" class="col-sm-10">
      <FormElementSelect @elementChanged="(name, data) => $emit('elementChanged', name, data)" :formData="formData"
        :element="element" :lang="lang" :targetId="targetId" :wasValidated="wasValidated" />
    </div>
    <div v-if="'checkbox'.indexOf(element.type) >= 0" class="col-sm-10">
      <FormElementCheckbox @elementChanged="(name, data) => $emit('elementChanged', name, data)" :formData="formData"
        :element="element" :lang="lang" :targetId="targetId" :wasValidated="wasValidated" />
    </div>
    <div v-if="'radioButton'.indexOf(element.type) >= 0" class="col-sm-10">
      <FormElementRadioButton @elementChanged="(name, data) => $emit('elementChanged', name, data)" :formData="formData"
        :element="element" :lang="lang" :targetId="targetId" :wasValidated="wasValidated" />
    </div>
  </div>
</template>

<script>
import FormElementTextInput from './SFormElementTextInput.vue';
import FormElementTextareaInput from './SFormElementTextareaInput.vue';
import FormElementNumberInput from './SFormElementNumberInput.vue';
import FormElementAmountInput from './SFormElementAmountInput.vue';
import FormElementEmailInput from './SFormElementEmailInput.vue'
import FormElementSelect from './SFormElementSelect.vue'
import FormElementCheckbox from './SFormElementCheckbox.vue'
import FormElementRadioButton from './SFormElementRadioButton.vue'
import FormElementBodyText from './SFormElementBodyText.vue'
export default {
  name: 'FormElement',
  props: ['element', 'targetId', 'pageIndex', 'elementIndex', 'lang', 'formData', 'wasValidated'],
  emits: ['editFormElement', 'elementChanged'],
  components: {
    FormElementTextInput, FormElementEmailInput, FormElementSelect, FormElementCheckbox,
    FormElementRadioButton, FormElementBodyText, FormElementNumberInput, FormElementAmountInput,
    FormElementTextareaInput
  },
  methods: {
    isRequired(value) {
      return value ? "*" : "";
    },
    sendEvent(event) {
      this.$emit(event, this.targetId);
    }
  },
  created() {
  }
}
</script>
