import { IFormSubmitService } from './IFormSubmitService';
import axios from 'axios'

export default class FormAdminService implements IFormSubmitService {

  private rootUrl = function (): string {
    if (window.location.origin.indexOf('localhost') == -1 && window.location.origin.indexOf('192.168.1.77') == -1) {
      return '';
    } else {
      return "http://localhost:8000";
    }
  }

  async submitForm(id: string = "foo", data: object): Promise<object> {
    try {
      const response = await axios.post(this.rootUrl() + `/submitFormData?id=${id}`, data)
      return response.data;
    } catch (error: any) {
      console.log('error.message', error.message)
      if (error.response) {
        return error.response.data
      }
      return error
    }
  }

  async getForm(id: string = 'foo'): Promise<object> {
    const response = await axios.get(this.rootUrl() + `/formStructure?id=${id}`)
    return response.data;
  }

  async submitReCaptcha(value: string): Promise<object> {
    const response = await axios.post(this.rootUrl() + `/verifyRecaptcha`, {
      'g-recaptcha-response': value
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  }
}