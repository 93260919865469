import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container p-0 m-0" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-warning alert-dismissible fade show",
  role: "alert"
}
const _hoisted_3 = { class: "d-flex justify-content-end mb-2" }
const _hoisted_4 = ["hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElement = _resolveComponent("FormElement")!
  const _component_SReCaptcha = _resolveComponent("SReCaptcha")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      ref: "myForm",
      class: _normalizeClass([{ 'was-validated': _ctx.wasValidated }, "row g-3 needs-validation ms-1 mt-2"]),
      onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleNextOrSubmit && _ctx.handleNextOrSubmit(...args)), ["prevent"])),
      novalidate: ""
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element, index) => {
        return (_openBlock(), _createBlock(_component_FormElement, {
          element: element,
          pageIndex: Number(_ctx.pageIndex),
          key: index,
          targetId: 'element-' + index,
          elementIndex: index,
          lang: _ctx.lang,
          formData: _ctx.formData,
          wasValidated: _ctx.wasValidated,
          onElementChanged: _ctx.elementChanged,
          required: ""
        }, null, 8, ["element", "pageIndex", "targetId", "elementIndex", "lang", "formData", "wasValidated", "onElementChanged"]))
      }), 128))
    ], 34),
    (_ctx.alertVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.alertMessage) + " ", 1),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.alertVisible = false)),
            "aria-label": "Close"
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLastPage && !_ctx.formStructure.hideCaptcha)
      ? (_openBlock(), _createBlock(_component_SReCaptcha, {
          key: 1,
          onOnRecaptchaTokenResponse: _ctx.onRecaptchaTokenResponse,
          class: "mb-3"
        }, null, 8, ["onOnRecaptchaTokenResponse"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "btn btn-secondary ms-1",
        hidden: _ctx.pageIndex == 0,
        onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.prevPage && _ctx.prevPage(...args)), ["stop"]))
      }, "< Vorige", 8, _hoisted_4),
      _createElementVNode("button", {
        class: _normalizeClass([_ctx.isLastPageOfForm(_ctx.pageIndex) ? 'btn-primary' : 'btn-secondary', "btn ms-1"]),
        onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleNextOrSubmit && _ctx.handleNextOrSubmit(...args)), ["stop"]))
      }, _toDisplayString(_ctx.isLastPage ? 'Submit' : 'Volgende >'), 3)
    ])
  ]))
}