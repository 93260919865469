<template>
  <div class="container-fluid p-0 m-0">
    <SNavBar @languageChanged="languageChanged" :languages="formStructure.languages" :formId="formId" />
    <div class="container main ">
      <router-view :formStructure="formStructure" :selectedElement="selectedElement" :lang="lang"
        @onDataReceived="onDataReceived" />
    </div>
  </div>
</template>

<script lang="ts">
import SNavBar from './components/SNavBar.vue'

export default {
  name: 'App',
  data() {
    return {
      lang: 'nl',
      formStructure: {} as { languages: [] },
      selectedElement: null,
      loadStaticData: true,
      formId: null,
    }
  },
  components: {
    SNavBar
  },
  methods: {
    onDataReceived(data: Object) {
      this.formStructure = data;
    },
    languageChanged(lang: string) {
      this.lang = lang
    }
  },
  mounted() {
    setTimeout(() => {
      this.formId = this.$route.query.id;
    }, 200)
  }
}
</script>
<style>
body {
  /* ivm met fixed-top navbar */
  padding-top: 70px;
}
</style>