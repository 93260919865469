import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid p-0 m-0" }
const _hoisted_2 = { class: "container main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SNavBar = _resolveComponent("SNavBar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SNavBar, {
      onLanguageChanged: $options.languageChanged,
      languages: $data.formStructure.languages,
      formId: $data.formId
    }, null, 8, ["onLanguageChanged", "languages", "formId"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view, {
        formStructure: $data.formStructure,
        selectedElement: $data.selectedElement,
        lang: $data.lang,
        onOnDataReceived: $options.onDataReceived
      }, null, 8, ["formStructure", "selectedElement", "lang", "onOnDataReceived"])
    ])
  ]))
}