import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../pages/SHomeView.vue'
import FormSubmitPage from '../pages/SFormSubmitPage.vue'
import ConfirmSubmitPage from '../pages/SConfirmSubmitPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/submit/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/submit/form',
    name: 'form',
    component: FormSubmitPage
  },
  {
    path: '/submit/confirm',
    name: 'confirm',
    component: ConfirmSubmitPage
  },
  {
    path: '/submit/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/SAboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
