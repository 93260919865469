/* eslint-disable */
<template>
  <div class="container m-0 p-0">
    <div class="row  m-1">
      <div class="col-0 col-lg-2"/>
      <div class="col-12 col-lg-8 ">
        <FormBuilder :lang="lang" :formStructure="formStructure" :formId="formId"/>
      </div>
      <div class="col-0 col-lg-2"/>
    </div>
  </div>
</template>

<script lang="ts">
import FormBuilder from '../components/form/SFormBuilder.vue'
import ServiceFactory from '../services/ServiceFactory'
import {IFormSubmitService} from '../services/IFormSubmitService'
import {defineComponent} from 'vue'

const _ = require('lodash');
export default defineComponent({
  name: "FormSubmitPage",
  props: ['lang'],
  components:{FormBuilder},
  emits:['onDataReceived'],
  watch: {
    formStructure(value:any) {
      if(value) {
        this.init()
      }
    },
    lang( value) {
      if(value) {
        this.init()
      }
    }
  },
  data() {
    return {
      formTitle: '',
      pageTitle: '',
      formDescription: '',
      pages: [],
      tableSlug: '',
      service: {} as IFormSubmitService,
      formStructure: {} as any,
      formId: '' as string,
    }
  },
  methods: {
    init() {
      if( this.formStructure && this.formStructure.formTitle && this.lang) {
        this.formTitle = this.formStructure.formTitle[this.lang]
        this.formDescription = this.formStructure.formDescription[this.lang]
        this.pages = this.formStructure.pages
        this.tableSlug = _.kebabCase(this.formTitle)
      }
    },
    getPageTitle(page:any, lang:string):string {
      if(page && lang) {
        return page.pageTitle[lang]
      }
      return '';
    },
  },
  async mounted() {
    this.service = ServiceFactory.getService('formSubmit')
    this.formId = this.$route.query.id;
    this.formStructure = await this.service.getForm(this.formId)
    this.$emit('onDataReceived', this.formStructure)
    this.init();
  }
})
</script>