<template>
  <div class="container p-0">
    <div class="input-group has-validation">
      <input :ref="'inputField_' + element.name" :value="value" @blur="elementChanged" type="number"
        :step="element.step || 1" :id="'input_' + element.name" class="form-control"
        :placeholder="getTextFor(element.placeholder, lang)" :required="element.required" aria-describedby="nameHelp">
    </div>
    <div class="invalid-feedback" :style="invalidStyle">{{ getTextFor(element.invalid, lang) }}</div>
    <div id="nameHelp" class="form-text">{{ getTextFor(element.explanation, lang) }}</div>
  </div>
</template>

<script>
export default {
  name: 'FormElementNumberInput',
  props: ['element', 'lang', 'targetId', 'wasValidated', 'value'],
  emits: ['elementChanged'],
  data() {
    return {
      invalidStyle: {},
    }
  },
  watch: {
    wasValidated(value) {
      console.log('wasValidated', value)
      if (!value) {
        this.invalidStyle = {}
      } else {
        this.invalidStyle = {
          display: this.checkFieldValidity('inputField_' + this.element.name) ? 'none' : 'block'
        }
      }
    }
  },
  methods: {
    elementChanged() {
      this.$emit('elementChanged', this.element.name, this.$refs['inputField_' + this.element.name].value)
    },
    checkFieldValidity(fieldRef) {
      const field = this.$refs[fieldRef];
      return field && field.checkValidity();
    },
    getTextFor(data, lang) {
      return data ? data[lang] : '';
    }
  },

}
</script>
