//import FormSubmitService from "./FormSubmitService";
import { IFormSubmitService } from "./IFormSubmitService";
import FormSubmitService from './FormSubmitService'

export default class ServiceFactory {

  static getService(serviceType: string): IFormSubmitService {
    switch (serviceType) {
      case 'formSubmit':
        return new FormSubmitService()
      default:
        throw new Error(`Unknown service type: ${serviceType}`);
    }
  }
}