<template>
  <div class="container p-0 mt-2">
    <div class="row">
        <div v-for="(option,index) in element.options" :key="index" class="col-auto ">
          <div class="form-check form-check-inline ">
            <input @change="checkboxChanged($event)" class="form-check-input" type="checkbox" :id="'inlineCheckbox-'+index" v-model="elData[option.name]" :required="option.required">
            <label class="form-check-label" for="inlineCheckbox1">{{getTextFor(option.text,lang)}}</label>
            <div class="invalid-feedback" :hidden="invalidFeedbackHidden" >{{ getTextFor(option.invalid,lang) }}</div>
          </div>
        </div>
        <div class="form-text">{{ getTextFor(element.explanation,lang) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormElementCheckbox',
  props: ['element', 'lang', 'targetId','formData', 'wasValidated'],
  data() {
    return {
      elData: {},
      invalidStyle: {},
      invalidFeedbackHidden: true,
    }
  },
  watch: {
    wasValidated(value) {
      console.log('checkbox.wasValidated', value)
      if(!value) {
        this.invalidStyle = {}
      } else {
        this.invalidStyle = {
          display: this.elData !== {} ? 'none' : 'block',
          height: 'auto'
        }
        this.invalidFeedbackHidden = this.elData !== {} ? false : true;
      }
    }
  },
  methods: {
    checkboxChanged(){
      this.$emit('elementChanged', this.element.name, this.elData)
    },
    getTextFor(data, lang) {
      return data ? data[lang] : '';
    },
    checkFieldValidity(fieldRef) {
      const field = this.$refs[fieldRef];
      return field && field.checkValidity();
    }
  },
  created() {
    if(this.formData[this.element.name]){
      this.elData = this.formData[this.element.name]
      for(let i in this.element.options) {
        const option = this.element.options[i]
        if(this.elData[option.name] === undefined){
          this.elData[option.name] = false
        }
      }
    }
  }
}
</script>

