<template>
  <div class="container ">
    <div  style="width:304px;height:80px;" id="recaptcha"></div>
  </div>
</template>

<script>
export default {
  name: 'SReCaptcha',
  emits: ['onRecaptchaTokenResponse'],
  mounted() {
    this.loadRecaptcha();
  },
  methods: {
    loadRecaptcha() {
      if (window.grecaptcha && window.grecaptcha.render) {
        window.grecaptcha.render('recaptcha', {
          'sitekey' : '6LfIrXMpAAAAADG0ahgTCcHJUQj7L3TFTKiVpuJw',
          'callback' : this.verifyCallback,
        });
      } else {
        setTimeout(this.loadRecaptcha, 500);
      }
    },
    verifyCallback(response) {
      this.$emit('onRecaptchaTokenResponse', response)
    },
  }
}
</script>

