<template>
  <div class="container p-0 mt-2">
    <div class="row">
      <div class="col-10">
        <div v-for="(option,index) in element.options" :key="index" class="form-check form-check-inline">
          <input @change="checkboxChanged($event)" class="form-check-input" type="radio" :required="element.required"
            :name="'radio'+element.name" :id="'inlineRadio-'+index" :value="option.value" v-model="elData.value">
          <label class="form-radio-label" :for="'inlineRadio-'+index">{{getTextFor(option.text,lang)}}</label>
        </div>
        <div class="invalid-feedback" :style="invalidStyle">{{ getTextFor(element.invalid,lang) }}</div>
        <div class="form-text">{{ getTextFor(element.explanation, lang) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormElementRadioButton',
  props: ['element', 'lang', 'targetId', 'formData', 'wasValidated'],
  emits: ['elementChanged'],
  data() {
    return {
      elData: {value:null},
      invalidStyle: {},
    }
  },
  watch: {
    wasValidated(value) {
      if(!value) {
        this.invalidStyle = {}
      } else {
        this.invalidStyle = {
          display: this.elData.value !== null ? 'none' : 'block'
        }
      }
    }
  },
  methods: {
    checkboxChanged(){
      console.log('checkboxChanged', this.elData)
      this.$emit('elementChanged', this.element.name, this.elData.value)
    },
    getTextFor(data, lang) {
      return data ? data[lang] : '';
    }
  },
  created() {
    if(this.formData[this.element.name]){
      this.elData.value = this.formData[this.element.name]
    }
  }
}
</script>

