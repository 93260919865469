<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-0 col-md-2" />
      <div class="card text-bg-primary col-12 col-md-8">
        <div v-if="confirmSubmitText[lang]" class="card-body" :innerHTML="confirmSubmitText[lang]"></div>
        <div v-if="!confirmSubmitText[lang]" class="card-body">
          <h2 class="card-title">Dank voor uw aanmelding!</h2>
          <p class="card-text">Wij hebben u een bevestiging van uw deelname per e-mail verstuurd. Hierin vindt u alle
            informatie die u nodig heeft met een link naar het programma.</p>
          <p class="card-text">Wij kijken uit naar uw deelname!</p>
          <p class="card-text"><b>Events Team Building Balance</b></p>
        </div>
      </div>
      <div class="col-0 col-md-2" />
    </div>
  </div>
</template>

<script>
import ServiceFactory from '../services/ServiceFactory'

export default {
  name: 'ConfirmSubmitPage',
  props: ['lang'],
  data() {
    return {
      isDynamicText: false,
      confirmSubmitText: {},
      formStructure: null,
      service: null
    }
  },
  watch: {
    formStructure(value) {
      if (value) {
        if (value.confirmSubmitText) {
          this.confirmSubmitText = value.confirmSubmitText
          this.isDynamicText = true
        }
      }
    }
  },
  methods: {
    checkAvailabilityContent(formStructure) {
      return formStructure && formStructure.confirmSubmitText
    }
  },
  async mounted() {
    this.service = ServiceFactory.getService('formSubmit')
    this.formId = this.$route.query.id;
    this.formStructure = await this.service.getForm(this.formId)
  }
}
</script>