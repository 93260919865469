<template>
  <div class="container p-0">
    <div class="input-group has-validation">
      <select ref="selectField" v-model="formData" @blur="elementChanged" class="form-select" :required="element.required">
        <option value="" selected>{{ getTextFor(element.placeholder,lang) }}</option>
        <option v-for="option in element.options" :value="option.value" :key="option.value" >{{ getTextFor(option.text,lang) }}</option>
      </select>
    </div>
    <div class="invalid-feedback" :style="invalidStyle">{{ getTextFor(element.invalid,lang) }}</div>
    <div id="nameHelp" class="form-text">{{ getTextFor(element.explanation,lang) }}</div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
export default defineComponent({
  name: 'FormelementSelect',
  props: ['element', 'lang', 'targetId', 'wasValidated'],
  data() {
    return {
      formData: "",
      invalidStyle: {},
    }
  },
  watch: {
    wasValidated(value) {
      if(!value) {
        this.invalidStyle = {}
      } else {
        this.invalidStyle = {
          display: this.checkFieldValidity('selectField') ? 'none' : 'block'
        }
      }
    }
  },
  methods: {
    elementChanged() {
      this.$emit('elementChanged', this.element.name, this.formData)
    },
    checkFieldValidity(fieldRef) {
      const field = this.$refs[fieldRef];
      return field && field.checkValidity();
    },
    getTextFor(data, lang) {
      return data ? data[lang] : '';
    }
  }
})
</script>