<template>
  <div class="container p-0 m-0">
    <form ref="myForm" :class="{ 'was-validated': wasValidated }" class="row g-3 needs-validation ms-1 mt-2"
      @submit.prevent="handleNextOrSubmit" novalidate>
      <FormElement v-for="(element, index) in elements" :element="element" :pageIndex="Number(pageIndex)" :key="index"
        :targetId="'element-' + index" :elementIndex="index" :lang="lang" :formData="formData"
        :wasValidated="wasValidated" @elementChanged="elementChanged" required />
    </form>
    <div v-if="alertVisible" class="alert alert-warning alert-dismissible fade show" role="alert">{{ alertMessage }}
      <button type="button" class="btn-close" @click="alertVisible = false" aria-label="Close" />
    </div>
    <SReCaptcha @onRecaptchaTokenResponse="onRecaptchaTokenResponse" class="mb-3"
      v-if="isLastPage && !formStructure.hideCaptcha" />
    <div class=" d-flex justify-content-end mb-2">
      <button class="btn btn-secondary ms-1" :hidden="pageIndex == 0" @click.stop="prevPage">&lt; Vorige</button>
      <button :class="isLastPageOfForm(pageIndex) ? 'btn-primary' : 'btn-secondary'" class="btn ms-1"
        @click.stop="handleNextOrSubmit">{{ isLastPage ? 'Submit' : 'Volgende >' }}</button>
    </div>
  </div>
</template>

<script lang="ts">
import FormElement from './SFormElement.vue'
import SReCaptcha from '../SReCaptcha.vue'
import { defineComponent } from 'vue'
import ServiceFactory from '../../services/ServiceFactory'
import { IFormSubmitService } from '../../services/IFormSubmitService'

export default defineComponent({
  name: 'FormBuilder',
  props: ['lang', 'formStructure', 'formId'],
  components: { FormElement, SReCaptcha },
  data() {
    return {
      pageTitle: '',
      pageIndex: 0,
      pageDescription: '',
      pages: [],
      page: null as any,
      elements: [],
      formData: {} as any,
      wasValidated: false,
      isLastPage: false,
      showRecaptcha: true,
      loadingTimeout: 30000,
      alertVisible: false,
      alertMessage: '' as string,
      formService: {} as IFormSubmitService,
    }
  },
  watch: {
    formStructure(value: any) {
      if (value) {
        this.initData()
      }
    },
    pageIndex(value) {
      if (value !== null) {
        this.initData()
        this.isLastPage = this.isLastPageOfForm(value)
      }
    }

  },
  methods: {
    elementChanged(name: string, data: any) {
      console.log('SFormBuilder.elementChanged', name, data)
      this.formData[name] = data
    },
    initData() {
      console.log("initData: ", this.formStructure.pages)
      if (this.formStructure && this.formStructure.pages && this.lang) {
        this.pages = this.formStructure.pages
        this.page = this.pages[this.pageIndex]
        this.elements = this.page.elements
        this.pageTitle = this.page.pageTitle[this.lang]
        this.pageDescription = this.page.pageDescription[this.lang]
        this.isLastPage = this.isLastPageOfForm(this.pageIndex)
      }
    },
    prevPage() {
      if (this.pageIndex > 0) {
        this.pageIndex--
        this.wasValidated = false;
      }
    },
    nextPage() {
      if (this.pageIndex < this.formStructure.pages.length - 1) {
        this.pageIndex++
      }
    },
    isLastPageOfForm(pageIndex: number) {
      if (!this.pages || this.pages.length == 0) {
        return false;
      }
      if (this.pages.length == 1) {
        return true;
      }
      return pageIndex == this.pages.length - 1
    },
    handleNextOrSubmit() {
      this.wasValidated++;// = true;
      if (this.checkFormValidity()) {
        if (this.isLastPageOfForm(this.pageIndex)) {
          console.log('Form submitted', this.formData, this)
          this.submitForm(this.formData)
        } else {
          this.wasValidated = false;
          this.nextPage()
        }
      } else {
        console.log('Form is invalid!');
      }
    },
    checkFormValidity() {
      const form = this.$refs.myForm;
      return form.checkValidity();
    },
    async onRecaptchaTokenResponse(value: string) {
      const result = await this.formService.submitReCaptcha(value)
      console.log('onRecaptchaTokenResponse', result)
    },
    async submitForm(formData: { _lang: string, __template: string }) {
      this.alertVisible = false
      this.alertMessage = ''
      formData._lang = this.lang
      const response = await this.formService.submitForm(this.formId, formData)
      if (response.success) {
        this.$router.push(`/submit/confirm?id=${this.formId}`)
      } else {
        this.alertVisible = true;
        this.alertMessage = response.message
      }

    }
  },
  async created() {
    await this.initData()
    this.formService = ServiceFactory.getService('formSubmit')
    setTimeout(() => {
      this.isLastPage = this.isLastPageOfForm(this.pageIndex)
    }, 100)

  }
})
</script>
