import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import axios from 'axios';
// Configureer Axios globaal. 
//Hiermee is toegestaan Cross Domain Cookies te versturen.
axios.defaults.withCredentials = true;

createApp(App).use(router).mount('#app')
