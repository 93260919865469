<template>
  <div class="container p-0">
    <div class="input-group has-validation">
      <input :ref="'inputField_'+element.name" :value="emailValue" @blur="elementChanged" :type="element.type" :id="'input_'+element.name" 
        class="form-control" :placeholder="getTextFor(element.placeholder,lang)" 
        :required="element.required" aria-describedby="nameHelp">
    </div>
    <div class="invalid-feedback" :style="invalidStyle">{{getTextFor(element.invalid, lang)}}</div>
    <div id="nameHelp" class="form-text">{{ getTextFor(element.explanation, lang) }}</div>
  </div>
</template>

<script>
export default {
  name: 'FormElementEmailInput',
  props: ['element', 'lang', 'targetId', 'wasValidated', 'valueObj'],
  emits: ['elementChanged'],
  data() {
    return {
      emailValue: '',
      invalidStyle: {},
    }
  },
  watch: {
    valueObj(obj) {
      if(obj) {
        if(obj.value) {
          this.emailValue = obj.value
        } else {
          this.emailValue = ''
        }
      }
    },
    wasValidated(value) {
      if(!value) {
        this.invalidStyle = {}
      } else {
        this.invalidStyle = {
          display: this.checkFieldValidity('inputField_'+this.element.name) ? 'none' : 'block'
        }
      }
    }
  },
  methods: {
    elementChanged() {
      let data = {value: this.$refs['inputField_'+this.element.name].value}
      if(this.element.confirmationEmail) {
        if(this.element.emailSubject) data.emailSubject = this.element.emailSubject[this.lang];
        if(this.element.template) data.template = this.element.template[this.lang];
        if(this.element.htmlTemplate) data.htmlTemplate = this.element.htmlTemplate[this.lang];
      }
      this.$emit('elementChanged', this.element.name, data)
    },
    checkFieldValidity(fieldRef) {
      const field = this.$refs[fieldRef];
      return field && field.checkValidity();
    },
    getTextFor(data, lang) {
      return data ? data[lang] : '';
    },
  },
  created(){
    if(this.valueObj && this.valueObj.value) {
      this.emailValue = this.valueObj.value
    } 
  }

}
</script>
