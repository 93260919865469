<template>
  <div :id="'bodyText_'+getUniqueId()" class="container p-0" :innerHTML="bodyText"></div>
</template>
<script>
const _ = require('lodash');
export default{
  name: 'FormElementBodyText',
  props: ['element', 'lang'],
  data() {
    return {
      bodyText: 'hallo world!'
    }
  },
  watch:{
    element(value) {
      if(value){
        this.bodyText = this.getTextFor(this.element.text, this.lang)
      }
    },
    lang(value) {
      if(value) {
        this.bodyText = this.getTextFor(this.element.text, this.lang)
      }
    }
  },
  methods: {
    getTextFor(data, lang) {
      return data ? data[lang] : '';
    },
    getUniqueId(){
      return _.uniqueId('id_')
    }
  },
  created() {
    this.bodyText = this.getTextFor(this.element.text, this.lang)

  }
}
</script>