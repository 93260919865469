<template>
  <div class="container p-0">
    <div class="input-group has-validation">
      <textarea :ref="'textarea_' + element.name" @blur="elementChanged" :id="'textarea_' + element.name" :value="value"
        class="form-control" :placeholder="getTextFor(element.placeholder, lang)" :required="element.required"
        aria-describedby="nameHelp"></textarea>
    </div>
    <div class="invalid-feedback" :style="invalidStyle">{{ getTextFor(element.invalid, lang) }}</div>
    <div id="nameHelp" class="form-text">{{ getTextFor(element.explanation, lang) }}</div>
  </div>
</template>

<script>
export default {
  name: 'FormElementTextareaInput',
  props: ['element', 'lang', 'targetId', 'wasValidated', 'value'],
  emits: ['elementChanged'],
  data() {
    return {
      invalidStyle: {},
    }
  },
  watch: {
    wasValidated(value) {
      console.log('wasValidated', value)
      if (!value) {
        this.invalidStyle = {}
      } else {
        this.invalidStyle = {
          display: this.checkFieldValidity('textarea_' + this.element.name) ? 'none' : 'block'
        }
      }
    }
  },
  methods: {
    elementChanged() {
      this.$emit('elementChanged', this.element.name, this.$refs['textarea_' + this.element.name].value)
    },
    checkFieldValidity(fieldRef) {
      const field = this.$refs[fieldRef];
      return field && field.checkValidity();
    },
    getTextFor(data, lang) {
      return data ? data[lang] : '';
    }
  },

}
</script>
